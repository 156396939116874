<template>
  <div id="principal" style="display: flex; align-items: flex-start;">
		<div style="width: 150px; height: 80vh;">
			<div style="display: flex; align-items: center; justify-content: center;">
				<v-btn
					class="mt-2 mb-3"
					style="text-transform: none"
					color="primary"
					small>
					Upload de mapa
				</v-btn>
			</div>
			<div style="display: flex; align-items: baseline; justify-content: center;">
				<v-text-field
					v-model="zoom"
					label="Posição"
					min="14"
					max="19"
					@input="aplicaPosicao"
					placeholder="Posição"
					outlined
					filled
					background-color="#FFF"
					dense
					flat
					style="height: 20px; font-size: 12px"
					class="ml-2 mr-2"
					tabindex="0"
					type="number"
				></v-text-field>
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-icon
							@click="aplicaPosicao()"
						  color="primary"
						  v-on="on"
						  class="icon-with-text mr-2">
							mdi-arrow-right
						</v-icon>
					</template>
					<span>Aplicar Posição</span>
				</v-tooltip>
				<!-- <v-btn
					class="mt-1 mr-2"
					style="text-transform: none"
					color="primary"
					small> -->
					<!-- <v-icon color="green">mdi-check</v-icon> -->
				<!-- </v-btn> -->
			</div>

			<v-slider
				class="mt-4"
				@change="desenha_Imagem([imageBounds.left, 
										imageBounds.bottom,
										imageBounds.right,
										imageBounds.top
										]
										)"
				v-model="imgRotacao"
				min="0"
				max="4"
				label-color="#FFFFFF"
				step="0.01"				
				ticks
				vertical
				dense>
				<template v-slot:append>
						<v-text-field
							id="text-slider"
							@input="desenha_Imagem([imageBounds.left, 
											imageBounds.bottom,
											imageBounds.right,
											imageBounds.top
											]
											)"
							v-model="imgRotacao"
							class="mt-0 pt-0"
							
							type="number"
							color="#FFFFFF"
							style="width: 60px"
						></v-text-field>
					</template>
				</v-slider>
		</div>
   	<div
   	  ref="map"
   	  id="map"
   	  style=" z-index:0"
   	  :style="{ top: '44px', width: '100%', height: getHeight()+'px',  'z-index': '0' } ">
		</div>

  </div>  
</template>

<script type="text/javascript">

import store_site from "../../store/store_site"
import {API, 
		API_BLOB,
       } from "../../services/API";

//import { convertImgToBase64 } from "../../services/funcoes";

import "./MapaEmpreendimentos.css";
//import "../ModalNegociacao/ModalNegociacao.css";

// -- Mapa -----------------------------------------------------------------------------------
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
//import OSM from 'ol/source/OSM'
import BingMaps from "ol/source/BingMaps.js";
import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { defaults as DefaultControls, FullScreen } from "ol/control";
import { DragRotateAndZoom, defaults as defaultInteractions } from "ol/interaction";
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { Point } from 'ol/geom';
import { Fill, Style, Stroke, Icon } from "ol/style.js";
import { toLonLat, fromLonLat, transformExtent } from "ol/proj.js";
import Select from "ol/interaction/Select.js";
import { click, pointerMove, altKeyOnly } from 'ol/events/condition.js';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import {
    Projection,
    addCoordinateTransforms,
    addProjection,
    get as getProjection,
    getTransform
  } from "ol/proj";

import pin_imagePath from './pin.png' 
import { rotateProjection } from "./Mapa_Funcoes"

export default {

	props:[
		'cod_empreendimento'
	],
	components: {
	},
	data() {
		return {
			store_site : store_site,

			blue:  0,
			result: { },
			map  : null,
			map2 : null,
			geo_JSON : null,
			pol_source1 : null,
			pol_vector1 : null,
			pol_source2 : null,
			pol_vector2 : null,
			id     	    : null, 
			mapEmp      : {},
			imgMapa_URL : "",
			//ImgMapa : null,
			ls_CodEmpreendimento : "",

			// Selecoes ----------------------------------
			vector : null,

			imageBounds: {
				bottom: null,
				left: null,
				top: null,
				right: null,
			},			
			img_opacity: 1,
			imageSize: {
				width: null,
				height: null,
			},
			lat: null,
			lng: null,
			zoom: null,
			rotacao: null,
			pol_lote: 0,

			// Seleção de Quadra e Lote ------------
			vm_quadra_selected: {},
			vm_quadra_options: [],
          	imoveis 		: null,
          	imoveisSelected : null,

			marker1 : null,
			featureMarker1 : null,
			marker2 : null,
			featureMarker2 : null,
			styleMarker : null,
			imageLayer : null,
			imageSource : null,
			imgMapa : null,
			imgMapa_Base64 : null,
			imgRotacao: 0,
			
		}

	},

	beforeDestroy () {
		// console.log('beforeDestroy')
		this.store_site.ViscomSGI = false;
	},

	beforeMount() {
    this.$nextTick(() => {
			this.store_site.ViscomSGI = true;
    });
		//this.id = this.cod_empreendimento;
		this.id = 16;
		console.log("🚀 ~ file: MapaEnquadramento.vue:173 ~ beforeMount ~ this.id:", this.id)
		//this.id = 1; // 15 = Agnello - Veredas
	},

	async mounted () {
		// Seleciona o elemento input
		var inputElement = document.querySelector('#text-slider');
		console.log("🚀 ~ file: MapaEnquadramento.vue:222 ~ mounted ~ inputElement:", inputElement)

		// Verifica se o elemento foi encontrado
		if (inputElement) {
			// Adiciona o ID ao elemento input
			inputElement.step = "0.01"
		}


		// Busca Dados do Empreendimento -------------------------------
		let resultado = await this.ByEmpreendimento(this.id);
		if (resultado) {
			this.mapEmp = resultado.data.result;

			///+++ Quando for Lazarus
			this.imageBounds = {
				bottom: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lng), // lat
				left: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lat), // lng
				top: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lng), // lat
				right: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lat), // lng
			},
			///+++ Quando for NodeJS, lng e lat invertido
			//  this.imageBounds = {
			// 	left: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lng), // lat
			// 	bottom: parseFloat(this.mapEmp.empreend_mapa_sw_bound_lat), // lng
			// 	right: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lng), // lat
			// 	top: parseFloat(this.mapEmp.empreend_mapa_ne_bound_lat), // lng
			// },

			this.img_opacity = 1,
			this.imageSize = {
				width: parseFloat(this.mapEmp.empreend_mapa_width),
				height: parseFloat(this.mapEmp.empreend_mapa_height),
			},
			
			///+++ Quando for Lazarus
			this.lat = parseFloat(this.mapEmp.empreend_mapa_longitude),
			this.lng = parseFloat(this.mapEmp.empreend_mapa_latitude),
			///+++ Quando for NodeJS, lng e lat invertido
			// this.lng = parseFloat(this.mapEmp.empreend_mapa_longitude),
			// this.lat = parseFloat(this.mapEmp.empreend_mapa_latitude),

			this.zoom = parseFloat(this.mapEmp.empreend_mapa_zoom),
			this.rotacao = parseFloat(this.mapEmp.empreend_mapa_rotacao),
			this.pol_lote = 0,
			this.imgRotacao = parseFloat(this.mapEmp.empreend_imagem_rotacao)
		}

		// -- Bing Layer -------------------------------------------------------------
		this.layer_Bing = new TileLayer({
			visible: true,
			preload: Infinity,
			source: new BingMaps({
				key: "AjqLNOOSzrQfPcNyK5safWtF7DI41xjmTF4Q7jUWyAAd-rX-Zu2Cn2HptKEJIte8",
				imagerySet: "AerialWithLabels",
				maxZoom: 19,
			}),
		});

		// -- Mapa --------------------------------------------------------------------
 		this.map = new Map({
 			controls: DefaultControls().extend([new FullScreen()]),
 			interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
 			target: this.$refs['map'],
 			view: new View({
				zoom: this.zoom,
				center: fromLonLat([this.lng, this.lat]),
 			}),
 			layers: [this.layer_Bing],
		});
	    this.map.getView().on('change:rotation', () => {
	        //console.log("rotacao: ", this.map.getView().getRotation());
  		});

        // -- Loteamento -------------------------------------------------------------
		this.imgMapa_URL = await this.MapaImagemByEmpreendimento(this.id);
		await this.desenha_Markers(this.imageBounds.left, this.imageBounds.bottom, "Marker1");
		await this.desenha_Markers(this.imageBounds.right, this.imageBounds.top, "Marker2");
		var imageExtent = [ fromLonLat([this.imageBounds.left, this.imageBounds.bottom])[0],
							fromLonLat([this.imageBounds.left, this.imageBounds.bottom])[1],
							fromLonLat([this.imageBounds.right, this.imageBounds.top])[0],
							fromLonLat([this.imageBounds.right, this.imageBounds.top])[1]
						]
		await this.desenha_Imagem(imageExtent);
		this.map.getView().setRotation(this.rotacao);

		// Poligonos ----------------------------------------------------------------
		this.geo_JSON = {};
      	this.pol_source1 = new VectorSource({
        	projection: "EPSG:3857",
        	format: new GeoJSON({}),
      	});
      	this.pol_vector1 = new VectorLayer({
        	source: this.pol_source1,
        	style: this.drawStyle,
      	});
      	this.map.addLayer(this.pol_vector1);
		this.atualizaPoligonosDB(this.id);

		this.addEventListener();
	},

	methods : {
		async aplicaPosicao() {
			this.map.getView().setZoom(this.zoom);
			this.map.getView().setCenter(fromLonLat([this.lng, this.lat]));
		},
		
		async addEventListener() {
			var _this = this;
        this.map.once("rotate", function() {
				//console.log("rotate");
				//this.map.getView().getRotation();
			});

			window.addEventListener("resize", function (e) {
				if (e) {
					e.stopImmediatePropagation();
					clearTimeout(window.updateSizeTimer);
					window.updateSizeTimer = setTimeout(async function () {
						var orgSize = _this.map.getSize();
						if (orgSize) {
							var newSize = [orgSize[0] + 100, await _this.getHeight() + 100];
							_this.map.setSize(newSize);
							_this.$refs['map'].style.height = await _this.getHeight() + "px";
							const view = await _this.map.getView();

							view.calculateExtent(_this.map.getSize());
							//map.getView().fit(extent, {size: newSize});
							//console.log(map.getTargetElement().style.height);
							//const li_Height = getHeight() + "px";
							//map.getTargetElement().style.height = li_Height;
							//map.setSize([map.getTargetElement().style.width + "px", li_Height]);
							//map.setSize([300, 400]);
						}
					}, 500);
				}
			})
		},

		async ByEmpreendimento(cod_empreendimento) {
			const resposta = await API.get(`site/mapa_empreendimento`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				return resposta;
			}
		},

		async MapaImagemByEmpreendimento(cod_empreendimento) {
			const resposta = await API_BLOB.get(`site/mapa_imagem`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				const file = new Blob([resposta.data], { type: "image/png" });
				const url = URL.createObjectURL(file);
				return url;
			}
		},

		async MapaByEmpreendimento(cod_empreendimento) {
			const resposta = await API.get(`site/mapa_json`, {
				params: {
					cod_empreendimento : cod_empreendimento,
				},
			});
			if (resposta) {
				return resposta;
			}
		},

		drawStyle(feature) {
			var color = feature.values_.cor;
			if (!color) 
				color = "#FF0000"
			return [
				new Style({
					fill: new Fill({
						color: this.rgba(color),
					}),
				}),
			];
		},

		getHeight() {
			return window.innerHeight - 120;
		},

		// transforma coordenadas
		transform(extent) {
			return transformExtent(extent, "EPSG:4326", "EPSG:3857");
		},

		rgba(p_cor) {
		  return "rgba(" +
						(p_cor = p_cor.replace("#", ""))
							.match(new RegExp("(.{" + p_cor.length / 3 + "})", "g"))
							.map(function (l) {
								return parseInt(p_cor.length % 2 ? l + l : l, 16);
							})
							.concat(0.5 || 1)
							.join(",") +
						")"
		},

		async atualizaPoligonosDB() {
			var _this = this;
			const resultado = await this.MapaByEmpreendimento(this.id);
			if (resultado) {
				_this.geo_JSON = resultado.data.result;
				_this.pol_source2 = new VectorSource({
				projection: "EPSG:3857",
				features: new GeoJSON().readFeatures(this.geo_JSON, {
					featureProjection: "EPSG:3857",
				}),
				format: new GeoJSON({}),
				});
				_this.pol_vector2 = new VectorLayer({
				source: _this.pol_source2,
				style: _this.drawStyle,
				});
			}
			await _this.map.removeLayer(_this.pol_vector1);
			await _this.map.addLayer(_this.pol_vector2);
			_this.pol_vector1 = this.pol_vector2;
			_this.pol_vector2 = {};
			_this.pol_source2 = {};
			//this.fitBounds();
		},

		async desenha_Markers(p_lng, p_lat, p_Nome) {
			var _this = this;
			var iconFeature = new Feature(new Point(fromLonLat([p_lng, p_lat])));
			var point_vector = new VectorLayer({
				source: new VectorSource({
					features: [iconFeature],
				}),
			})
			await this.map.addLayer(point_vector);
			point_vector.set('name', p_Nome);
			point_vector.setStyle(new Style({
				image: new Icon(({
					src: pin_imagePath,
					scale: 0.8,
				}))
			}))
			var modify = new Modify({
				features: new Collection([iconFeature])
			});	
			this.map.addInteraction(modify);
			if (p_Nome == "Marker1")
				iconFeature.on('change',function() {
					console.log("Marker1", [this.getGeometry().getCoordinates()[0],
					               		 	this.getGeometry().getCoordinates()[1],
										 	fromLonLat([_this.imageBounds.right, _this.imageBounds.top])[0],
										 	fromLonLat([_this.imageBounds.right, _this.imageBounds.top])[1]]
								);
					_this.desenha_Imagem([	this.getGeometry().getCoordinates()[0],
					               		 	this.getGeometry().getCoordinates()[1],
										 	_this.imageBounds.right,
										 	_this.imageBounds.top
										]);
				}, iconFeature);
			if (p_Nome == "Marker2")
				iconFeature.on('change',function() {
					console.log("Marker2", [fromLonLat([_this.imageBounds.left, _this.imageBounds.bottom])[0],
										 	fromLonLat([_this.imageBounds.left, _this.imageBounds.bottom])[1],
										 	this.getGeometry().getCoordinates()[0],
										 	this.getGeometry().getCoordinates()[1]]
								);
					_this.desenha_Imagem([	_this.imageBounds.left,
										 	_this.imageBounds.bottom,
										 	this.getGeometry().getCoordinates()[0],
										 	this.getGeometry().getCoordinates()[1]
										]);
				}, iconFeature);
		},

		desenha_Imagem(imageExtent) {
			this.imageBounds.left   = imageExtent[0];
			this.imageBounds.bottom = imageExtent[1];
			this.imageBounds.right  = imageExtent[2];
			this.imageBounds.top	= imageExtent[3];
			this.map.removeLayer(this.imageLayer);
			this.imageLayer = new ImageLayer( { opacity: 0.5 } );
			const projection = this.map.getView().getProjection();
			imageExtent = transformExtent(imageExtent, 'EPSG:3857', projection);

			this.imageSource = new Static({
				url: this.imgMapa_URL,
				crossOrigin: "",
				projection: rotateProjection("EPSG:3857", (Math.PI / 2) * this.imgRotacao, imageExtent),
				imageExtent: imageExtent,
				imageSmoothing: true,
				opacity: 0.5,
			});
			this.imageLayer.setSource(this.imageSource);
			this.map.addLayer(this.imageLayer);
			console.log("update empreendimento " +
			            "    set empreend_mapa_sw_bound_lat='" + toLonLat([imageExtent[0], imageExtent[1]])[0] + "'," +
						"        empreend_mapa_sw_bound_lng='" + toLonLat([imageExtent[0], imageExtent[1]])[1] + "'," +
						"        empreend_mapa_ne_bound_lat='" + toLonLat([imageExtent[2], imageExtent[3]])[0] + "'," +
						"        empreend_mapa_ne_bound_lng='" + toLonLat([imageExtent[2], imageExtent[3]])[1] + "'," +
			            "  where cod_empreendimento=" +  this.id + ';');
			console.log('Img:', toLonLat([imageExtent[0], imageExtent[1]]), toLonLat([imageExtent[2], imageExtent[3]]) );
		},

	},

}

// Exemplo OverLay -----------------------------------
// https://openlayers.org/en/latest/apidoc/module-ol_Overlay-Overlay.html
//import Overlay from 'ol/Overlay';
//var popup = new Overlay({
//  element: document.getElementById('popup')
//});
//popup.setPosition(coordinate);
//map.addOverlay(popup);


</script>

<style scoped>

.text-white{
	color: white;

}
.white-text-field {
   
      color: white;
   
  }

	.icon-with-text {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.icon-with-text::after {
  content: attr(data-text);
  /* margin-left: 8px; */
}
            
</style>